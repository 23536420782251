import { Select } from "@mui/material";
import axios from "axios";
import React from "react"
import { useState } from "react";

export default function CreatorForm(){

  const formHandler = () => {
    window.location.href = "https://forms.gle/k9D2qsswsEpgARgK9";
  }
  
      return (
        <div className="CreatorFormDiv">
        {/* <div className="FormDiv"> */}
        {/* <img src="sponxerX.png" className="FormLogo" /> */}
        {/* <form>
            <input type="text" placeholder="First Name" className="FormInput1"/>
            <input type="text" placeholder="Last Name" className="FormInput2"/>
            <input type="text" placeholder="Link To Social" className="FormInput3"/>
            <select name="gender" className="genderInput">
              <option value="" disabled selected >Select Gender</option>

              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">other</option>
            </select>
            <select name="price" className="genderInput2">
              <option value="" disabled selected >Campaign Price</option>

              <option value="10-20">10k-20k</option>
              <option value="20-50">20k-50k</option>
              <option value="50-70">50k-70k</option>
              <option value="70-100">70k-100k</option>
              <option value="100-150">100k-150k</option>
              <option value="150-200">150k-200k</option>
            </select>
            <select name="survey" className="genderInput3">
              <option value="" disabled selected >Have you done any deals before ?</option>

              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <button className="buttonToSubmit"><b>Submit</b></button>
        </form> */}
        <button class="square-button" onClick={formHandler}>Fill out the creator form</button>
        {/* </div> */}
        </div>
      )
} 