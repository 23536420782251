import react from "react";
import '../css/Design.css';

//Nabar - The navigation bar at the top of website.
// LogoDesign  - the logo in the top right of the navigation bar.
//Navlinks - contain all the links that can be used for navigation in the website.
export default function Navbar(){
    return (

        <div className="Navbar" id = "navbarId">
        
         <a href="/"><img src="sponxerNameLogo.png" className="logoDesign" /></a>

         <div className="navLinkBox">
         <a href="#footerId"><b/><h2 className="NavLinks" >Support</h2></a>
         <a href="/#details"><b/><h2 className="NavLinks" id="details1">Blog</h2></a>
         <a href="/#choices"><b/><h2 className="NavLinks">Dashboard</h2></a>
         </div>

         

        </div>

        
    );
}