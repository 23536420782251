
import './App.css';
import SearchPage from './Features/SearchPage';
import './Dependencies/css/Design.css';
import './Dependencies/css/Animation.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResultPage from './Features/ResultPage';
import Navbar from './Dependencies/common/navbar';
import Footer from './Dependencies/common/Footer';
import HomePage from './Features/LandingPage';
import CreatorForm from './Features/CreatorForm';

function App() {
  return (
    <div>
    <Navbar/>
    <BrowserRouter>
        <Routes>
          <Route path="/form" element={<CreatorForm />}/>
          <Route path="/" element={<HomePage />}/>
          <Route path="/search" element={<SearchPage />}/>
          <Route path="/result/" element={<ResultPage />}/>
        </Routes>
    </BrowserRouter>
    <Footer/>
    </div>
    
    
    
  )
}

export default App;
