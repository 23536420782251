import axios from "axios";

export default function HomePage(){

    const handleSearchRefer = () => {
        window.location.href = "/search";
    }

    const handleFormRefer = () => {
        window.location.href = "/form";
    }

    return(
        <div className="homepageDiv">

        <div className="showcaseDiv">
        <div className="showCaseSubDiv">
        <div className="f1Div">
        <h1 className="firstHeading">Focus</h1>

        </div>

        <div className="f2Div">
        <h1 className="secondHeading">On Creation</h1>

        <a href="#choices">
        <div className="startedButtonDiv">
        <h3 className="startedText">Get Started</h3>
        <img src="arrow.svg" className="getStarted" />
        </div>
        </a>
        </div>
        </div>

        <img src="phoneGirl.png" className="phonePhoto" />

        </div>

        <div className="SelectOption" id="choices">
        <div className="choices" >

        <button className="choicesButton" onClick={handleFormRefer} >
        <h1 className="choicesButtonTitle">Creator</h1>
        <img src="influencer.svg" className="selectIcon" />
        </button>

        <button className="choicesButton" onClick={handleSearchRefer}>
        <h1 className="choicesButtonTitle">Business</h1>
        <img src="businessGirl.svg" className="selectIcon" />
        </button>

        </div>

        </div>
        <div className="finalDivLanding" id="details">
        <img src= "guyWithPhone.svg" className="landingPageLast"/>
        <div className="finalDiv">
        <h1 className="finalDivText">Why Choose us ?</h1>  
        <ul className="finalDivList">
            <li className="listElements">24/7 Support</li>
            <li className="listElements">Hand Picked Creators</li>
            <li className="listElements">Budget Friendly</li>
        </ul>
        </div>      
        </div>
        
        </div>
    )
}