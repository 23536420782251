import React from "react";

export default function Card(props){
    return (
        <div className="CardDesign">
        <h1 className="CardName"><b>{props.name}</b></h1>
        <h3 className="cardFollower">Followers:&nbsp;{props.followers + "k+"} </h3>
        <a href={(props.link)}><button className="buttonTosocial"><img className="iconsOfCards" src={"colourIcons/"+ props.platform +".svg"}/></button></a>
        <img src={"gender/"+ props.gender +".svg"} className="CardGender"/>
        </div>
    );
}