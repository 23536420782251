
import React from "react";
import axios from "axios";
import { useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function SearchBoxSelect(){

  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  };

  
  const [keyword, setKeyword] = useState("");

  const KeywordHandler = (e) => {
    setKeyword(e.target.value);
  }

  const sendData = () => {

    if(keyword === ""){
      alert("Please select a company number");
      return;
    }


    handleOpen();

    axios.post("https://sponxerbackend.onrender.com/searchRequest", keyword)

    .catch((err)=>{
      console.log(err)
    })

    setTimeout(
      () => {
        window.location.href = "/result"
      }, 
      5000
    );
    
  }


    return(
        
     <div style={{backgroundColor : "black"}}>

     
     

     <form>
        <select name="categories" id="categories" className="CategoriesBox" onChange={KeywordHandler}  >

        <option value="" disabled selected>Select your company number</option>

          <option value="one">Company 1</option>
          <option value="two">Company 2</option>
          <option value="three">Company 3</option>
          <option value="four">Company 4</option>
          <option value="five">Company 5</option>

        </select>

        <button className="SearchButton" type="button" onClick={sendData} ><img src="searchIcon.svg" className="searchIconAlt"/></button>

        </form>

     
        <div className="searchHeadlineBox">
     <h1 className="SearchHeadline">Pick your creator today ,</h1>
     <h2 className="SearchSubline">from real variety</h2>
     </div>

     <img src="searchIconSearchPage.svg" className="searchSvg"/>

        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


     </div>  

    )
}