import React from "react";
import Card from "./components/card";
import { useState , useEffect} from "react";
import axios from "axios";


export default function Overview(){

    // state rerenders when the data has arrived , dont't import data or this will cause issues with many rerenders.
    const[info , setinfo] = useState(
        {
            "data" :
        {
        "cdata" : [
          {
            "gender": "male",
            "platform": "youtube",
            "followers": 0,
            "name": "None",
            "prof": "youtube.com"
          }
        ]
      }
    }
      );
  
    // to fetch the api data 
    useEffect(() => {

        const getData = () => {

        axios.get("https://sponxerbackend.onrender.com/searchResponse")

        .then ((res) => { 
            setinfo(res);
        })

        .catch((err) => {
            console.log(err);
        })

    }

    getData();
    

   },[info]);

   // checking before having if data is there or not and the returning the best suitable answer


    const UserMessage = () => {
        if(info != undefined){

            let data = (info.data.cdata);

            return (
                data.map(
                    (data) => 
                    <Card name={data.name} followers={data.followers} platform={data.platform} gender={data.gender} link={data.prof} />
                )
            )
            
            

            }
    }

    
    return (
        
        <div className="resultDiv">
        
        <div className="enclosedLinksResult">
        <a href="/search"><button className="RedirectButton"><h1 className="redirrectText">Search again</h1></button></a>
        <a href="https://wa.me/918882723617?text=I%20want%20to%20enquire%20about%20sponsorship%20deals%20through%20your%20platform."><div className="whatsappButton"><img src="whatsapp.svg" className="whatsappSvg"/><h1 className="WhatsappText">Contact us to book</h1></div></a>
        </div>
        
        {UserMessage()}

        </div>

    );
}