import React from "react";

export default function Footer(){
    return (
        <div className="Footer" id="footerId">
<div className="IconPlusName">
<h1 className="NameTitle">SPONXER</h1>
        <div className="iconPack">
        
        <a href="https://twitter.com/SponxerOfficial"><img src="xIcon.png"  className="iconsFooter"/></a>
        <a href="https://www.linkedin.com/company/sponxer/"><img src="linkedin.svg" className="iconsFooter"/></a>
        <a href="https://www.instagram.com/sponxer.official/"><img src="instaIcon.svg" className="iconsFooter"/></a>
        </div>
</div>
        <div className="footerLinks">
            <h2 className="linktoFooterLinks">About</h2>
            <h2 className="linktoFooterLinks">Privacy Policy</h2>
            <h2 className="linktoFooterLinks">Terms & Conditions</h2>
        </div>

        <div className="footerContact">
            <h2 className="linktoFooterContact1"><b>Contact Us:</b></h2>
            <h2 className="linktoFooterContact2">sponxer.official@gmail.com</h2>
        </div>

        </div>
    )
}